import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import PostList from '../../components/PostList'
import Breadcrumbs from '../../components/Breadcrumbs'
import SEO from '../../components/SEO'

const Category = ({ data }) => {
  const category = data.categoriesPt
  const posts = [...data.allPostsOnePt.edges, ...data.allPostsTwoPt.edges]

  return (
    <Layout>
      <SEO
        title={category.name}
        description={category.description}
        post={false}
        canonical={`${process.env.GATSBY_HOST_URL}/pt/category/${category.slug}`}
      />

      <div className="category">
        <Breadcrumbs post={category} isCategory={true} />

        <h1>{category.name}</h1>

        <PostList posts={posts} />
      </div>
    </Layout>
  )
}

export default Category

export const query = graphql`
  query($id: String!, $slug: String!) {
    categoriesPt(id: { eq: $id }) {
      name
      slug
      description
      language {
        slug
      }
    }

    allPostsOnePt(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          name
          slug
          date(formatString: "DD/MM/YYYY", locale: "pt-BR")
          imageUrl
          language {
            slug
          }
          meta {
            content
          }
        }
      }
    }

    allPostsTwoPt(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          name
          slug
          date(formatString: "DD/MM/YYYY", locale: "pt-BR")
          imageUrl
          language {
            slug
          }
          meta {
            content
          }
        }
      }
    }
  }
`
